<template>
  <div class="bodys">
    <div class="cont">
      <div class="cont-right">
        <div class="cont-center">
          <div class="cont_tab">
            <div :class="tab == 1 ? 'tab tabs' : 'tab'" @click="tab1">
              全部
              <div class="tabj" v-show="tab == 1"></div>
            </div>
            <div :class="tab == 2 ? 'tab tabs' : 'tab'" @click="tab2">
              未开始
              <div class="tabj" v-show="tab == 2"></div>
            </div>
            <div :class="tab == 3 ? 'tab tabs' : 'tab'" @click="tab3">
              进行中
              <div class="tabj" v-show="tab == 3"></div>
            </div>
            <div :class="tab == 4 ? 'tab tabs' : 'tab'" @click="tab4">
              已结束
              <div class="tabj" v-show="tab == 4"></div>
            </div>
          </div>
        </div>
        <div class="tab_heng"></div>
        <div
          class="cont-center"
          style="padding-bottom: 20px; overflow: auto"
          v-infinite-scroll="load"
          :infinite-scroll-immediate="false"
          :infinite-scroll-disabled="disabled"
        >
          <!-- 全部 未开始 进行中 已结束 根据判断 status 的值显示 -->
          <div class="tab_list" v-for="(item, index) in list" :key="index">
            <div class="list_cont">
              <div class="list_top">
                <div class="list_num">{{ item.num }}</div>
                <div class="list_status1" v-show="item.status == 0">未开始</div>
                <div class="list_status2" v-show="item.status == 1">进行中</div>
                <div class="list_status3" v-show="item.status == 2">已结束</div>
              </div>
              <div class="list">
                <div class="list_name">{{ item.title }}</div>
                <div class="list_zhi">{{ (item.amount / 100).toFixed(2) }}</div>
              </div>
              <div class="list">
                <div class="list_admin">
                  <div class="list_xiang">
                    预期年化收益率:
                    <span
                      >{{ item.annual_profit / 100 }}%+{{
                        item.float_profit / 100
                      }}%</span
                    >
                  </div>
                  <div class="list_xiang">
                    周期: <span>{{ item.operate_days }}天</span>
                  </div>
                  <div class="list_xiang">
                    每日收益:
                    <span>{{ (item.profit / 100).toFixed(2) }}元</span>
                  </div>
                </div>
                <div class="list_admin">
                  <div class="list_xiang">最大风险: <span>0%</span></div>
                  <div class="list_xiang">
                    <i v-if="item.status==2">到期总收益:</i>
                    <i v-else>已累计收益:</i>
                    <span>{{ item.all_profit / 100 }}元</span>
                  </div>
                  <div class="list_xiang" v-if="item.status == 0">
                    募集期截止:
                    <van-count-down class="redcolor"
                      :time="item.raise_end * 1000 - date"
                      format="DD天HH小时mm分钟"
                    />
                  </div>
                  <div class="list_xiang" v-else>
                    到期时间:
                    <span class="redcolor">{{
                      (item.end_at * 1000) | format("YYYY-MM-DD HH:mm")
                    }}</span>
                  </div>
                </div>
                <div class="list_admin2">
                  <div
                    class="list_btn"
                    v-show="item.status != 1"
                    @click="
                      $router.push({
                        path: '/collection',
                        query: { id: item.id },
                      })
                    "
                  >
                    查看详情
                  </div>
                  <div
                    class="list_btn2"
                    v-show="item.status == 1"
                    @click="
                      $router.push({
                        path: '/collection',
                        query: { id: item.id },
                      })
                    "
                  >
                    查看记录
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { myInvest } from "@network/mine";
export default {
  name: "Myjoin",
  data() {
    return {
      date:new Date(),
      count: 0,
      page: "1",
      limit: "5",
      tab: 1, //tab切换
      status: "", //进行状态   0 未开始 1 进行中 2 结束
      list: [],
    };
  },
  created() {
    this.load();
  },
  components: {},
  computed: {
    noMore() {
      //当起始页数大于总页数时停止加载
      return this.list.length >= this.count;
    },
    disabled() {
      return this.noMore;
    },
  },
  methods: {
    load() {
      this.myInvest();
      this.page++;
    },

    myInvest() {
      myInvest(this.page, this.limit, this.status).then((res) => {
        console.log(res);
        if (res.code != 0) {
          if (res.code == 403) {
            this.$storage.remove("token");
            this.$message.error({ message: "请重新登录", offset: 300 });
            this.$router.replace("/home");
            return false;
          }
          this.$message.error({
            message: "获取失败",
            offset: 300,
          });

          return false;
        } else {
          this.count = res.count;
          this.list.push(...res.data);
          // this.loading = false;
          // // 数据全部加载完成
          // if (this.list.length >= res.count) {
          //   this.finished = true;
          // }
        }
      });
    },
    tab1() {
      this.tab = 1;
      this.status = "";
      this.page = "1";
      this.list = [];
      this.load();
    },
    tab2() {
      this.tab = 2;
      this.status = "0";
      this.page = "1";
      this.list = [];
      this.load();
    },
    tab3() {
      this.tab = 3;
      this.status = "1";
      this.page = "1";
      this.list = [];
      this.load();
    },
    tab4() {
      this.tab = 4;
      this.status = "2";
      this.page = "1";
      this.list = [];
      this.load();
    },
  },
};
</script>
<style lang='less' scoped>
@import "../../../../assets/css/vipCenter/ChildComps/myjoin.less";
</style>
